import { useRef, useState } from 'react';
import useClickOutside from '../../../hooks/useClickOutside';
import Select from '../Select';

const YearBy = ({ setFilters, filters, classname }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const currentYear = new Date().getFullYear();
  const options = ["--", ...Array.from({ length: currentYear - 2022 + 1 }, (_, i) => 2022 + i)];
 
 
  const ref = useRef(null);

  useClickOutside(ref, () => setIsSelectOpen(false));

  function handleSetFilters(e) {
    const { innerText } = e.target;
    setIsSelectOpen(false);
    setFilters({
      ...filters,
      year: innerText === '--' ? '' : innerText,
    });
  }

  const optionsCMP = options.map((item) => (
    <span
      role="button"
      onClick={handleSetFilters}
      key={item}
      type="button"
      style={filters?.year === item ? { backgroundColor: '#F2F4F8' } : {}}
    >
      {item}
    </span>
  ));

  function handleOnSelectClick() {
    setIsSelectOpen(!isSelectOpen);
  }

  return (
    <div className={classname} ref={ref}>
      <Select onClick={handleOnSelectClick} value={filters?.year || '--'} isSelectOpen={isSelectOpen}>
        <option>{filters?.year || '--'}</option>
      </Select>
      {isSelectOpen && <div>{optionsCMP}</div>}
    </div>
  );
};

export default YearBy;
