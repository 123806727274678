import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Doc from '../../../pages/Doc'
import { setActiveMenuItem, setIsOpenMenu, setScreenSize } from '../../../store/slices/userSlice'
import SideBar from '../../SideBar'
import constants from './constants'
import NavBarHeader from './NavBarHeader'

import style from './style.module.scss'

function getLink(location) {
  const locationHash = location.hash.substring(1)
  const names = locationHash.split('/')
  let name = locationHash
  if (names?.length > 1) name = names[1]
  return name
}

const AppShell = () => {
  const { activeMenuItem, isOpenMenu, screenSize } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const onClickedLink = (ev) => {
    const { target } = ev
    const linkNameByData = target.getAttribute('data-link')

    const linkNameById = target.id
    navigate(`/#${linkNameById ? linkNameByData : linkNameByData}`)
  }
  
  useEffect(() => {
    const ElLinkHref = document.querySelectorAll('.toclink, .toclinkInline')
    ElLinkHref.forEach((el) => {
      el.addEventListener('click', onClickedLink)
    })

    return () => {
      ElLinkHref.forEach((el) => {
        el.removeEventListener('click', onClickedLink)
      })
    }
  }, [])

  useEffect(() => {
    const linkName = getLink(location)
    if (activeMenuItem === linkName) return
    setTimeout(() => {
      const anchorTarget = document.getElementById(linkName)
      if (!anchorTarget) return
      anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
      dispatch(setActiveMenuItem(linkName))
    }, 100)
  }, [location])

  useEffect(() => {
    setWindowSize()
    window.addEventListener('resize', setWindowSize)
    return () => {
      window.removeEventListener('resize', setWindowSize)
    }
  }, [])

  const closeMenu = () => {
    dispatch(setIsOpenMenu(false))
  }

  const setWindowSize = () => {
    const windowSize = window.innerWidth
    if (windowSize <= constants.Mobile) {
      dispatch(
        setScreenSize({
          mode: 'Phone',
          size: windowSize,
        })
      )
    } else if (windowSize <= constants.Tablet) {
      dispatch(
        setScreenSize({
          mode: 'Tablet',
          size: windowSize,
        })
      )
    } else {
      dispatch(
        setScreenSize({
          mode: 'Desktop',
          size: windowSize,
        })
      )
    }
  }
  return (
    <div className={style.appShell}>
      <NavBarHeader />
      <div className={style.appBodyContainer}>
        <SideBar />
        <Fragment>
          <div className={style.currentPageContainer}>
            <Routes>
              <Route path="*" element={<Doc />}></Route>
            </Routes>
          </div>
        </Fragment>
        {screenSize?.mode === 'Phone' && isOpenMenu && (
          <div className={style.docSideBarOpenContainer} onClick={closeMenu}></div>
        )}
      </div>
    </div>
  )
}

export default AppShell
