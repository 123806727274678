import { createSlice } from '@reduxjs/toolkit'
import isSandbox from '../../utils/isSandbox'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isShown: false,
    contactIsShown: false,
    isAuthorized: false,
    token: '',
    activeMenuItem: '',
    authorizedToken: '',
    scrollComponent: 'about',
    isOpenMenu: false,
    tokenSandbox: null,
    screenSize: {
      mode: 'Desktop',
      size: 1600,
    },
  },
  reducers: {
    setContactIsShown: (state, action) => {
      state.contactIsShown = action.payload
    },
    setIsShown: (state) => {
      state.isShown = !state.isShown
    },
    setIsOpenMenu: (state, action) => {
      state.isOpenMenu = action.payload
    },
    logout: (state) => {
      state.isAuthorized = false
      state.token = ''
    },
    setScrollComponent: (state, action) => {
      state.scrollComponent = action.payload
    },
    setActiveMenuItem: (state, action) => {
      state.activeMenuItem = action.payload
    },
    setScreenSize: (state, action) => {
      state.screenSize = action.payload
    },

    setIsAuthorized: (state, action) => {
      const isSandboxEnv = isSandbox()
      const { response, session_token } = action.payload
      state.isAuthorized = response
      state.authorizedToken = session_token

      if (response) {
        if (isSandboxEnv) state.tokenSandbox = null
        state.isShown = false
      }
    },

    setToken: (state, action) => {
      const isSandboxEnv = isSandbox()
      state.token = action.payload
      if (isSandboxEnv) state.tokenSandbox = action.payload
    },
  },
})

export const {
  setIsShown,
  setToken,
  setIsAuthorized,
  setContactIsShown,
  setIsOpenMenu,
  logout,
  setScrollComponent,
  setActiveMenuItem,
  setScreenSize,
} = userSlice.actions

export default userSlice.reducer
