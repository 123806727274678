import style from './style.module.scss'

const NoteDocumenation = ({ handleNoteCloseBtnClick }) => {
  return (
    <div className={style.noteDocumenationContainer}>
      <div className={style.closeBtn} onClick={handleNoteCloseBtnClick}>
        <img src="/media/close.svg" alt="close note" />
      </div>
      <p>
        We've upgraded our database technology to enhance performance and reliability. No code changes are
        required on your end. Additionally, historical AR calls are now supported. If you encounter any
        issues, please let us know. More details can be found below.
      </p>
    </div>
  )
}

export default NoteDocumenation
