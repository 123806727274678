import SectionWrapperGettingStarted from '../SectionWrapperGettingStarted'
import style from '../style.module.scss'

const StepOne = () => {
  return (
    <SectionWrapperGettingStarted linkName={'step_one'}>
      <div className={style.stepOneContainer}>
        <div className={style.headlineContainer}>
          <h3 className="toclink" data-link="getting_started/step_one">
            1. Request a Secret Key
          </h3>
          <h4>
            {'Request an API secret key from PlanetWatchers.\nIn the meanwhile you can get some sample responses from our '}
            <a href="https://api-sandbox.crop-cycle.com" target="_blank" rel="noreferrer">
              sandbox environment
            </a>{' '}
            using the key: "sandbox_api_key".
          </h4>
        </div>
      </div>
    </SectionWrapperGettingStarted>
  )
}

export default StepOne
