import axios from 'axios'

export async function checkSessionValidity(session_token) {
  try {
    const { data } = await axios(`${process.env.REACT_APP_API_URL}/check_token?token=${session_token}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${session_token}`,
        contentType: 'application/x-www-form-urlencoded',
      },
    })
    return data?.exists || false
  } catch (err) {
    console.error(err)
    return false
  }
}
