import classNames from 'classnames'
import style from '../style.module.scss'

const ParameterList = ({ children }) => {
  return (
    <div className={classNames(style.parameterListContainer, style.sessionLengthContainer)}>{children}</div>
  )
}

export default ParameterList
