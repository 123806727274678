import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getToken } from '../../../../store/actions/api.calls.actions'
import SearchLoader from '../../../layout/AppShell/SearchLoader'
import isSandbox from '../../../../utils/isSandbox'
import style from '../../style.module.scss'

const GetTokenForm = ({ filters, setFilters }) => {
  const dispath = useDispatch()
  const isSandboxEnv = isSandbox()
  const [isLoading, setIsLoading] = useState(false)

  const onChange = ({ name, value }) => {
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  const onExecute = async () => {
    if (!filters.apiKey) return
    setIsLoading(true)
    await dispath(getToken(filters.apiKey, filters.sessionLength || '86400'))
    setIsLoading(false)
  }

  return (
    <div className={style.filtersForm}>
      <div className={style.sessionLength}>
        <h2>session_length:</h2>
        <input
          type="text"
          name="sessionLength"
          placeholder="86400"
          value={filters.sessionLength}
          onChange={({ target }) => onChange(target)}
        />
      </div>
      <div className={style.apiKey}>
        <h2>Api-Key:</h2>
        <input
          type="text"
          name="apiKey"
          placeholder="Api-Key"
          value={filters.apiKey}
          onChange={({ target }) => onChange(target)}
        />
      </div>
      {isSandboxEnv && (
        <h3 className={style.sandboxKeyMsg}>Please type "sandbox_api_key" in the box above</h3>
      )}
      {!isLoading ? (
        <button
          className={`${style.btnExecute} ${!filters.apiKey ? style.disabled : ''}`}
          onClick={onExecute}
        >
          Execute{' '}
        </button>
      ) : (
        <button className={`${style.loader} ${style.btnExecute}`}>
          <SearchLoader /> Loading
        </button>
      )}
    </div>
  )
}

export default GetTokenForm
