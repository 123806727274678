import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import About from '../../components/About'
import ApiCalls from '../../components/ApiCalls'
import AuthorizeUser from '../../components/AuthorizeUser'
import GettingStarted from '../../components/GettingStarted'
import Pagination from '../../components/GettingStarted/Pagination'
import Glossary from '../../components/Glossary'
import Modal from '../../components/Modal'
import SectionWrapper from '../../components/SectionWrapper'
import Toast from '../../components/Toast'
import UnderstandingApiRequest from '../../components/GettingStarted/UnderstandingApiRequest'
import { checkIfTokenValid } from '../../store/actions/api.calls.actions'
import { setIsShown } from '../../store/slices/userSlice'
import style from './style.module.scss'
import NoteDocumenation from '../../components/NoteDocumention'
const Doc = () => {
  const docContainerRef = useRef()
  const { isShown, screenSize, tokenSandbox } = useSelector((state) => state.user)
  const [authErr, setAuthErr] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [toast, setToast] = useState(null)
  const [isNoteOpen, setIsNoteOpen] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isShown) setAuthErr(null)
  }, [isShown])

  const handleNoteCloseBtnClick = () => {
    setIsNoteOpen(!isNoteOpen)
  }
  const onOutSideClick = (ev) => {
    if (ev.target.classList.contains('modal-container')) {
      dispatch(setIsShown())
    }
  }

  const authorizeUser = async (tokenKey) => {
    if (tokenKey) {
      setIsLoading(true)
      const res = await dispatch(checkIfTokenValid(tokenKey, tokenSandbox))
      setIsLoading(false)

      if (!res) {
        setAuthErr({ title: '', type: 'error', text: 'Authorization with the following token failed' })
      } else setAuthErr(null)
    }
  }
  return (
    <div className={style.docContainer} ref={docContainerRef}>
      {isNoteOpen && <SectionWrapper linkName={''} classname={style.noteSectionWrapper}>
        <NoteDocumenation handleNoteCloseBtnClick={handleNoteCloseBtnClick} />
      </SectionWrapper>}

      {process.env.REACT_APP_IS_SANDBOX ? (
        <SectionWrapper linkName={''}>
          <h1 className={style.docContainerHeadline}>CropCycle API sandbox</h1>
          <h3 className={style.sandboxTitle}>
            Use this environment to familiarize yourself with the CropCycle API calls and responses. This can
            also be achieved through the CropCycle API CLI.
          </h3>
          <h4 className={style.sandboxSubtitle}>
            Make sure to follow the correct order of calls, as specified in the documentation page getting
            started section.
          </h4>
        </SectionWrapper>
      ) : (
        <h1 className={style.docContainerHeadlineDoc}>CropCycle API documentation</h1>
      )}
      {!process.env.REACT_APP_IS_SANDBOX && (
        <>
          <About />
          <GettingStarted />
          <UnderstandingApiRequest />
          <Pagination />
          <Glossary />
        </>
      )}
      <ApiCalls />
      {screenSize?.mode === 'Desktop' && <div className={style.docContainerBorder}></div>}

      <Toast toast={toast} />
      {isShown && (
        <Modal onOutSideClick={onOutSideClick}>
          <AuthorizeUser authorizeUser={authorizeUser} authErr={authErr} isLoading={isLoading} />
        </Modal>
      )}
    </div>
  )
}

export default Doc
