import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setActiveMenuItem } from '../../../../store/slices/userSlice'
import isSandbox from '../../../../utils/isSandbox'
import MenuItem from './MenuItem'
import MenuItemDrop from './MenuItemDrop'
import GettingStartedMenuDropDown from './GettingStartedMenuDropDown'
import ApiCallsMenuDropDown from './ApiCallsMenuDropDown'
import style from './style.module.scss'

const SideBarAccordion = () => {
  const dispatch = useDispatch()
  const isSandboxEnv = isSandbox()
  const { activeMenuItem } = useSelector((state) => state.user)
  const navigate = useNavigate()

  const onClickMenuItem = (name) => {
    dispatch(setActiveMenuItem(name))
    navigate(`/#${name}`)
  }

  return (
    <div className={style.sideBarAccordionContainer}>
      {!isSandboxEnv && (
        <>
          <MenuItem
            title="About PlanetWatchers"
            onClick={onClickMenuItem}
            activeMenuItem={activeMenuItem}
            name="about_plantwatchers"
          />
          <MenuItem
            title="About The API"
            onClick={onClickMenuItem}
            activeMenuItem={activeMenuItem}
            name="about_the_api"
          />
          <MenuItemDrop
            title="Getting Started"
            onClick={onClickMenuItem}
            activeMenuItem={activeMenuItem}
            name="getting_started"
          >
            <GettingStartedMenuDropDown name="getting_started" />
          </MenuItemDrop>
          <MenuItem
            title="Understanding API Request Parts"
            onClick={onClickMenuItem}
            activeMenuItem={activeMenuItem}
            name="understanding-api-request"
          />
          <MenuItem
            title="Pagination"
            onClick={onClickMenuItem}
            activeMenuItem={activeMenuItem}
            name="pagination"
          />
          <MenuItem
            title="Glossary"
            onClick={onClickMenuItem}
            activeMenuItem={activeMenuItem}
            name="glossary"
          />
        </>
      )}
      <MenuItemDrop
        title="API Calls"
        onClick={onClickMenuItem}
        activeMenuItem={activeMenuItem}
        name="api_calls"
      >
        <ApiCallsMenuDropDown activeMenuItem={activeMenuItem} name="api_calls" />
      </MenuItemDrop>
    </div>
  )
}

export default SideBarAccordion
