import { useSelector, useDispatch } from 'react-redux'
import { setResetAfterLogout } from '../../../../store/slices/apiSlice'
import { logout, setContactIsShown, setIsOpenMenu, setIsShown } from '../../../../store/slices/userSlice'
import isSandbox from '../../../../utils/isSandbox'
import style from './style.module.scss'

const NavBarHeader = () => {
  const dispatch = useDispatch()
  const isSandboxEnv = isSandbox()
  const { isAuthorized, screenSize, isOpenMenu } = useSelector((state) => state.user)
  
  const openMenu = () => {
    dispatch(setIsOpenMenu(!isOpenMenu))
  }
  const onSetIsShown = () => {
    dispatch(setContactIsShown(true))
  }

  const redirectToSandboxURL = () => {
    window.location.href = process.env.REACT_APP_SANDBOX_API_URL
  }

  const redirectToApiDocURL = () => {
    window.location.href = process.env.REACT_APP_API_WEB_URL
  }
  
  return (
    <div className={style.navBarHeaderContainer}>
      <div className={style.logoContainer}>
        {screenSize.mode === 'Phone' && (
          <div className={style.hamburgerIconContainer} onClick={openMenu}>
            <img src="/images/logos/hamburger-icon.svg" alt="hamburger-btn" />{' '}
          </div>
        )}
        <a href="#">
          <img src="/images/logos/cropcycle-logo.svg" alt="cropcycle-logo" />
        </a>
      </div>
      <div className={style.toggleContainer}>
        {!isSandboxEnv ? (
          <>
            <button className={style.topMenuBtn} onClick={onSetIsShown}>
              Contact Support
            </button>
            <button className={`${style.topMenuBtn} ${style.topSandboxBtn}`} onClick={redirectToSandboxURL}>
              Try in Sandbox
            </button>
          </>
        ) : (
          <button className={`${style.topMenuBtn} ${style.topSandboxBtn}`} onClick={redirectToApiDocURL}>
            Back to API docs
          </button>
        )}
        {isAuthorized ? (
          <button
            className={style.authorizeLogout}
            onClick={() => {
              dispatch(setResetAfterLogout())
              dispatch(logout())
            }}
          >
            Logout
          </button>
        ) : (
          <button className={style.authorizeLogin} onClick={() => dispatch(setIsShown())}>
            Authorize
          </button>
        )}
      </div>
    </div>
  )
}

export default NavBarHeader
