const StartFrom = ({ filters, setFilters }) => {
  const MAX_VALUE = 1000;

  const onChange = ({ name, value }) => {
    // Convert to number and validate
    const numValue = parseInt(value) || 0;
    // Ensure value is between 0 and MAX_VALUE
    const validValue = Math.min(Math.max(0, numValue), MAX_VALUE);
    
    setFilters({
      ...filters,
      [name]: validValue.toString(),
    })
  }

  return (
    <div>
      <input
        type="number"
        min="0"
        max={MAX_VALUE}
        name="start_from"
        placeholder="start_from"
        value={filters.start_from}
        onChange={({ target }) => onChange(target)}
      />
    </div>
  )
}

export default StartFrom
