

const StartFromParameter = () => {
  return (
    <div>
      <div>
        <h4>start_from</h4>
        <h5>string</h5>
      </div>
      <div>
        <h6>
          Results are returned starting from the value entered here. For example: entering '5' would
          return results starting from the sixth one.
        </h6>
      </div>
    </div>
  );
};

export default StartFromParameter;
