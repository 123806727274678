import SectionWrapperApiCalls from '../SectionWrapperApiCalls'
import style from '../style.module.scss'
import AcreageReportingByAcresReq from './AcreageReportingByAcresReq'
import AcreageReportingCropTypeReq from './AcreageReportingCropTypeReq'
import AcreageReportingForecastReq from './AcreageReportingForecastReq'
import AcreageReportingPlantingDatesReq from './AcreageReportingPlantingDatesReq'
import AcreageReportingReq from './AcreageReportingReq'

const AcreageReportingListOfCalls = () => {
  return (
    <>
      <div className={style.getTokenContainer}>
        <SectionWrapperApiCalls linkName={'acreage_reporting'}>
          <h2 className="toclink" data-link="api_calls/acreage_reporting">
            /acreage_reporting
          </h2>
          <p>Returns all acreage reporting data in a single response.</p>
          <p>
            Each object in the response represents a single CLU and includes information about the CLU’s
            planting date, types of crops identified, and the number of acres planted and operated. Each
            object also includes metadata associated with the particular CLU (e.g., farm ID, state code,
            etc.).
          </p>
          <p>
            For historical data access, see the Historical Data section where you can specify the year
            in the URL path.
          </p>
          <AcreageReportingReq />
        </SectionWrapperApiCalls>
        <SectionWrapperApiCalls linkName={'acreage_reporting_acres'}>
          <h2 className="toclink" data-link="api_calls/acreage_reporting_acres">
            /acreage_reporting/crop_acres
          </h2>
          <p>
            Returns the number of acres which have been operated (correct to two decimal places) for each CLU.
          </p>
          <p>
            This includes a combination of the number of planted acres, emerged acres, and classified acres
            that were detected by planetWatchers' Crop Acres (CA) service.
          </p>
          <p>
            If no planting operation was detected on a CLU, or if only typical growth was detected, the value
            returned will be “Not-Detected”.
          </p>
          <AcreageReportingByAcresReq />
        </SectionWrapperApiCalls>
        <SectionWrapperApiCalls linkName={'acreage_reporting_crop_types'}>
          <h2 className="toclink" data-link="api_calls/acreage_reporting_crop_types">
            /acreage_reporting/crop_types
          </h2>
          <p>
            Returns the following information for each CLU: type of crop(s) planted on the CLU, number of
            acres associated with each crop (correct to two decimal places), and the geometry (i.e., polygon
            overlay) of each crop.
          </p>
          <p>
            The crop types identified by planetWatchers' Crop Identification (CI) service include corn,
            soybean, cotton, and wheat.
          </p>
          <p>
            Besides these crops, other vegetation, soil, and non-agricultural areas (i.e., trees, buildings,
            roads, etc.) will also be included in the response, if detected.
          </p>
          <p>
            NOTE: planetWatcher's CI service can identify and report on up to three (3) items per CLU.</p>
            <p> 
            For example, identifying two types of crops plus other vegetation on the CLU would count as three items.
          </p>
          <AcreageReportingCropTypeReq />
        </SectionWrapperApiCalls>
        <SectionWrapperApiCalls linkName={'acreage_reporting_planting_dates'}>
          <h2 className="toclink" data-link="api_calls/acreage_reporting_planting_dates">
            /acreage_reporting/planting_dates
          </h2>
          <p>
            In the case of multiple operational units (or fields) within the same CLU, the latest planting
            date is reported.
          </p>
          <p>In case no planting operation is detected, a “Not-Detected” value will be assigned.</p>

          <AcreageReportingPlantingDatesReq />
        </SectionWrapperApiCalls>

        <SectionWrapperApiCalls linkName={'acreage_reporting_forecast'}>
          <h2 className="toclink" data-link="api_calls/acreage_reporting_forecast">
            /acreage_reporting/forecast
          </h2>
          <p>
            This API call will return the forecasted Acerage Reporting values for the CLUs provided. There are
            several requirements needed to produce the forecast so please consult your account manager.
          </p>

          <AcreageReportingForecastReq />
        </SectionWrapperApiCalls>
      </div>
    </>
  )
}

export default AcreageReportingListOfCalls
