import SectionWrapper from '../../SectionWrapper'
import style from './style.module.scss'

const UnderstandingApiRequest = () => {
  return (
    <SectionWrapper linkName={'understanding-api-request'}>
      <div className={style.understandingApiRequestContainer}>
        <h1 className="toclink" data-link="understanding-api-request">
          Understanding API request parts
        </h1>
        <h3>Every URL request is comprised of the following sections:</h3>
        <ul className={style.requestPartsExample}>
          <li>
            <h3>Base-URL:</h3>
            <h4>Always <span>https://api.crop-cycle.com</span>.</h4>
          </li>
          <li>
            <h3>Version:</h3>
            <h4>The current version is <span>V3</span>, but there might be times when a new version is available, and the previous version is still supported.</h4>
          </li>
          <li>
            <h3>Year:</h3>
            <h4>The 4-digit year annotation for which the data is required (e.g., <span>2024</span>). If the year is not specified, the system defaults to the new growing season starting April 1st. For example, 2028 data will be the default between April 2028 and April 2029 if not specified.</h4>
          </li>
          <li>
            <h3>API Call:</h3>
            <h4>The specific request, such as <span>/acreage_reporting/</span>.</h4>
          </li>
          <li>
            <h3>Optional Parameters:</h3>
            <h4>Found in the documentation below, including filtering and ordering options. For example:</h4>
            <h4><span>sort_by={'usda_clu_id'}&asc={'true'}</span> will return a list ordered by usda_clu_id in ascending order.</h4>
          </li>
        </ul>
        <h2>Example request:</h2>
        <div className={style.requestPartsExample}>
          <div>
            <span>https://api.crop-cycle.com/v3/2024/acreage_reporting?sort_by=usda_clu_id&start_from=10&limit=5&asc=false</span>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default UnderstandingApiRequest
