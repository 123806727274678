const tokenSample = {
  curl: ({ sessionLength, apiKey }) => {
    return `curl -X POST ${process.env.REACT_APP_API_URL}/get_token?session_length=${
      sessionLength || 86400
    } -H accept:application/json -H Api-Key:${apiKey}`
  },
  cli: ({ sessionLength, apiKey }) => {
    return `plw-cli get-token <${apiKey}> session length=${sessionLength || 86400} `
  },

  python: ({ sessionLength, apiKey }) => {
    return `import requests

    url="${process.env.REACT_APP_API_URL}/get_token"
    
    headers = {
          'accept': 'application/json',
          'Api-Key': '${apiKey}',
          'Content-Type': 'application/x-www-form-urlencoded',
    }
    
    params = {
          'session_length': '${sessionLength || 86400}',
    }
    
    response = requests.request('POST',url,  params=params, headers=headers)
    print(response.content)
      `
  },
  javascript: ({ sessionLength, apiKey }) => {
    return `fetch('${process.env.REACT_APP_API_URL}/get_token?session_length=${sessionLength || 86400}', {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Api-Key': '${apiKey}',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });    
    `
  },
}
export default tokenSample
