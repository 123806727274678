const resSampleAcreageReporting = {
  404: `
  {
    "message":"Data not found.",
    "status": 404
  }
  `,
  401: `
  {
    "description": "Authorization header is expected",
    "message": "authorization_header_missing"
  }
  `,
  400: `
  {
    "message":"Bad request {message}",
     "status" : 400
  }
  `,
  204: `
  {
     "status" : 204
  }
  `,
  200: ` 
  [
    {
      "agencies": ["42-006"],
      "crop_identification": [
        {
          "confidence": "forecast",
          "confidence_number": -1,
          "crop_code": 9100,
          "crop_name": "Other",
          "forecast": true,
          "identified_acres": 8.954424232859832,
          "last_updated": "2024-06-10 00:00:00",
          "link": "https://pw-*-data.s3.amazonaws.com/2024/CI_rasters/s2/*/prod_s2_T17RLP_2024/*.tif"
        }
      ],
      "harvest_date": "2024-05-21 00:00:00",
      "planting_date": {
        "confidence": "forecast",
        "last_updated": "2024-05-10 00:00:00",
        "planting_date": "2024-05-21 00:00:00"
      },
      "policies": ["200027202"],
      "total_acres": 15.513425899375878,
      "usda_clu_id": "00000336-42F9-4A93-9B39-3F267974D750",
      "usda_county_code": "005",
      "usda_farm_id": "148",
      "usda_field_id": "20",
      "usda_state_code": "42",
      "usda_tract_id": "383"
    }
  ]
  `,
}

export default resSampleAcreageReporting
