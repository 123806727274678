import axios from 'axios'
import isEmpty from '../utils/isEmpty'

const cleanFilters = (filters) => {
  const { start_from } = filters
  let newFilters = { ...filters, start_from: start_from || 0 }
  newFilters = Object.fromEntries(Object.entries(newFilters).filter(([_, v]) => v !== ''))
  return newFilters
}

export async function harvest(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/harvest`, {
      params: { ...filters },
      headers,
    })
    
    if (response?.status === 204) {
      return { status: 204 }
    } else if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch (error) {
    console.log(error)
    return { message: error?.response?.data?.message || error?.message, status: error?.status }
  }
}

export async function winds(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/wind_damage`, {
      params: { ...filters },
      headers,
    })

    if (response?.status === 204) {
      return { status: 204 }
    } else if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch (error) {
    console.log(error)
    return { message: error?.response?.data?.message || error?.message, status: error?.status }
  }
}

export async function acreageReportingPlantingDates(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }
  const yearPart = filters?.year ? `/${filters?.year}` : ''

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${yearPart}/acreage_reporting/planting_dates`,
      {
        params: { ...filters },
        headers,
      }
    )

    if (response?.status === 204) {
      return { status: 204 }
    } else if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch (error) {
    console.log(error)
    return { message: error?.response?.data?.message || error?.message, status: error?.status }
  }
}

export async function acreageReportingCropType(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }
  const yearPart = filters?.year ? `/${filters?.year}` : ''

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${yearPart}/acreage_reporting/crop_types`,
      {
        params: { ...filters },
        headers,
      }
    )

    if (response?.status === 204) {
      return { status: 204 }
    } else if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch (error) {
    console.log(error)
    return { message: error?.response?.data?.message || error?.message, status: error?.status }
  }
}

export async function acreageReportingAcres(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }
  const yearPart = filters?.year ? `/${filters?.year}` : ''

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${yearPart}/acreage_reporting/crop_acres`,
      {
        params: { ...filters },
        headers,
      }
    )

    if (response?.status === 204) {
      return { status: 204 }
    } else if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch (error) {
    console.log(error)
    return { message: error?.response?.data?.message || error?.message, status: error?.status }
  }
}

export async function acreageReportingForecast(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
    contentType: 'application/x-www-form-urlencoded',
  }
  const yearPart = filters?.year ? `/${filters?.year}` : ''

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${yearPart}/acreage_reporting/forecast`,
      {
        params: { ...filters },
        headers,
      }
    )

    if (response?.status === 204) {
      return { status: 204 }
    } else if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch (error) {
    console.log(error)
    return { message: error?.response?.data?.message || error?.message, status: error?.status }
  }
}

export async function acreageReporting(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/acreage_reporting`, {
      params: { ...filters },
      headers,
    })

    if (response?.status === 204) {
      return { status: 204 }
    } else if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch (err) {
    console.log(err)
    return { message: err?.message, status: err?.status }
  }
}

export async function acreageReportingHistory(filters, token) {
  filters = cleanFilters(filters)

  const headers = {
    accept: 'application/json',
    authorization: `Bearer ${token}`,
  }

  const yearPart = filters?.year ? `/${filters?.year}` : ''
  delete filters?.year

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}${yearPart}/acreage_reporting`, {
      params: { ...filters },
      headers,
    })

    if (response?.status === 204) {
      return { status: 204 }
    } else if (!isEmpty(response)) {
      return response?.data
    } else {
      return []
    }
  } catch (err) {
    console.log(err)
    return { message: err?.message, status: err?.status }
  }
}
