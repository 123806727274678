import { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAcreageReportingAcres } from '../../../../store/actions/api.calls.actions'
import ApiRequestForm from '../../ApiRequestForm'
import CodeSnippet from '../../CodeSnippet'
import ParameterList from '../../ParameterList'
import ResTypeCodeDisplay from '../../ResTypeCodeDisplay'
import resSampleAcreageReporting from '../../samples/resSampleAcreageReporting'
import StartFromParameter from '../../../Common/StartFromParameter';

import CalcMarginBottom from '../../hooks/CalcMarginBottom'
import style from '../../style.module.scss'
import filtersForm from '../../utils/filtersForm'

const AcreageReportingByAcresReq = ({isHistoricalYear=false}) => {

  const [resTypeCodeMarginBottom, setResTypeCodeMarginBottom] = useState(0)
  const [activeCodePreview, setActiveCodePreview] = useState('200')
  const [paramsMarginBottom, setParamsMarginBottom] = useState(0)
  const [codeConainerMarginBottom, setCodeConainerMarginBottom] = useState(0)
  const [filters, setFilters] = useState(filtersForm(null));
  const route = isHistoricalYear ? `${filters.year}/acreage_reporting/crop_acres` : 'acreage_reporting/crop_acres';
  const customFilters = isHistoricalYear ? filters : {...filters, year: null};

  const { isAuthorized, authorizedToken, screenSize } = useSelector((state) => state.user)
  const { acreageReportingAcres } = useSelector((state) => state.api)

  const dispatch = useDispatch()

  const codeRef = useRef()
  const parametersReqRef = useRef()
  const resTypeCodeRef = useRef()
  const resTypeRef = useRef()

  const isScreenSizeDesktop = useMemo(() => {
    return screenSize.mode === 'Desktop' ? { marginBottom: `${resTypeCodeMarginBottom}px` } : {}
  }, [screenSize, resTypeCodeMarginBottom])

  const isScreenSizeParmeterMarginBottomDesktop = useMemo(() => {
    return screenSize.mode === 'Desktop' ? { marginBottom: `${paramsMarginBottom}px` } : {}
  }, [screenSize, paramsMarginBottom])

  const res = useMemo(() => {
    if (!activeCodePreview) return ''
    if (activeCodePreview === '200') {
      return resSampleAcreageReporting['200']
    } else if (activeCodePreview === '401') {
      return resSampleAcreageReporting['401']
    } else if (activeCodePreview === '400') {
      return resSampleAcreageReporting['400']
    } else {
      return resSampleAcreageReporting['404']
    }
  }, [activeCodePreview])

  CalcMarginBottom({
    setParamsMarginBottom,
    response: acreageReportingAcres,
    resTypeRef,
    resTypeCodeRef,
    setResTypeCodeMarginBottom,
    setCodeConainerMarginBottom,
    codeRef,
    parametersReqRef,
  })

  const onExecute = async (filters) => {
    await dispatch(getAcreageReportingAcres(filters, authorizedToken))
  }

  return (
    <div className={style.parameterContainer}>
      <div ref={parametersReqRef} style={isScreenSizeParmeterMarginBottomDesktop}>
        <h3>Parameters</h3>
        <ParameterList>
          {isHistoricalYear && (
            <div className={style.sessionLengthContainer}>
              <div>
                <h4>year</h4>
                <h5>string</h5>
              </div>
              <div>
                <h6>The year for which to retrieve acreage reporting data</h6>
                <p>
                  Specify the year in YYYY format (e.g., 2023, 2024). This parameter is required and must be included in the URL path.
                </p>
              </div>
            </div>
          )}
          <div className={style.sessionLengthContainer}>
            <div>
              <h4>filter_by</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>Results can be filtered by the following values:</h6>
              <p>
                usda_clu_id, usda_tract_id, usda_field_id, usda_farm_id, usda_state_code, usda_county_code,
                policies, agencies, planting_dates(Format: YYYY-MM-DD)
              </p>
            </div>
          </div>
          <div>
            <div>
              <h4>filter_value</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>
                Results can be filtered by a specific value which is related to the filter_by value selected
                above.
              </h6>
              <p>
                <span>There are several such examples:</span> If usda_state_code was selected as a filter_by
                value, then to return results pertaining to Arizona only you would enter '04' (Arizona’s state
                code) here.
              </p>
              <h6 className={style.notesTitle}>Notes</h6>
              <ul className={style.notesListContainer}>
                <li>
                  <div></div>{' '}
                  <h6>If a filter_by value is selected, leaving this blank will return an error.</h6>{' '}
                </li>
                <li>
                  <div></div>
                  <h6>If no filter_by value is selected, entering a value here will return an error.</h6>{' '}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <h4>sort_by</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>Results can be sorted by the following values:</h6>
              <p>usda_clu_id, farm_tract_field, state_county, policies, agencies.</p>
              <h6 className={style.notesTitle}>Notes</h6>
              <ul className={style.notesListContainer}>
                <li>
                  <div></div>{' '}
                  <h6>
                    Choosing farm_tract_field will perform a sort by three values in the following order:
                    farm_number , then tract_number, then field_number.
                  </h6>{' '}
                </li>
                <li>
                  <div></div>
                  <h6>
                    Choosing state_county will perform a sort by two values in the following order:
                    usda_state_code, then usda_county_code.
                  </h6>{' '}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <h4>asc</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>
                The direction by which results are sorted. If true, results are sorted in ascending order; if
                false, they are sorted in descending order.
              </h6>
              <p>true, false</p>
            </div>
          </div>
          <StartFromParameter />
          <div>
            <div>
              <h4>limit</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>The number of results to return.</h6>
            </div>
          </div>
          <div>
            <div>
              <h4>incl_image</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>Results may include an image representing the relative location of the crop(s) in the field. For added security, the image is stored on a protected cloud location and the links provided are set to expire within 60 minutes from delivery.</h6>
              <h6 className={style.notesTitle}>Notes</h6>
              <ul className={style.notesListContainer}>
                <li>
                  <div></div>{' '}
                  <h6>
                    Use the following numbers for classification: <br />
                    11 - Wheat, 21 - Cotton, 41 - Corn, 81 - Soybeans, 9100 - Other, 9102 - NonAg.
                    <br/>
                    Please note: On the image classification raster, Other is classified as 101, and Non-Ag as 102.

                  </h6>{' '}
                </li>
              </ul>
            </div>
          </div>
        </ParameterList>
      </div>
      <CodeSnippet
        route={route}
        marginBottom={codeConainerMarginBottom}
        filters={{ ...customFilters }}
        resCodeDisplay={
          <ResTypeCodeDisplay
            resTypeCodeRef={resTypeCodeRef}
            setActiveCodePreview={setActiveCodePreview}
            activeCodePreview={activeCodePreview}
            res={res}
          />
        }
        title={'Request example'}
        codeRef={codeRef}
        response={acreageReportingAcres}
      >
        <ApiRequestForm
          route={route}
          filters={customFilters}
          isHistoricalYear={isHistoricalYear}
          onExecute={onExecute}
          isAuthorized={isAuthorized}
          isInclImage
          setFilters={setFilters}
        />
      </CodeSnippet>
      <div className={style.resTypeContainer} ref={resTypeRef} style={isScreenSizeDesktop}>
        <div>
          <h3>Responses</h3>
        </div>
        <ParameterList>
          <div>
            <div>
              <h4>200</h4>
            </div>
            <div>
              <h6 className={style.resType}>Success</h6>
            </div>
          </div>

          <div>
            <div>
              <h4>400</h4>
            </div>
            <div>
              <h6 className={style.resType}>Bad request</h6>
            </div>
          </div>
          <div>
            <div>
              <h4>401</h4>
            </div>
            <div>
              <h6 className={style.resType}>Unauthorized</h6>
            </div>
          </div>
          <div>
            <div>
              <h4>404</h4>
            </div>
            <div>
              <h6 className={style.resType}>Not found</h6>
            </div>
          </div>
        </ParameterList>
      </div>
    </div>
  )
}

export default AcreageReportingByAcresReq
