import axios from 'axios'

export async function authenticate(apiKey, sessionLength = 86400) {
  try {
    const res = await axios(`${process.env.REACT_APP_API_URL}/get_token?session_length=${sessionLength}`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Api-Key': apiKey,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    return res.data
  } catch (err) {
    return { message: err?.response?.data?.message, status: err?.response?.status }
  }
}
