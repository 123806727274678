import './style/style.scss'
import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet'
import store from './store/store'
import AppShell from './components/layout/AppShell'
import isSandbox from './utils/isSandbox'

function App() {
  const isSandboxEnv = isSandbox()
  return (
    <div className="App">
      <Helmet>
        <meta
          name="description"
          content={isSandboxEnv ? 'CropCycle API sandbox' : 'CropCycle API documentation'}
        />
        <title>
          {isSandboxEnv ? 'CropCycle API sandbox' : 'CropCycle API documentation'}
        </title>
      </Helmet>
      <Provider store={store}>
        <AppShell />
      </Provider>
    </div>
  )
}

export default App
