import { useState } from 'react'
import SearchLoader from '../../../layout/AppShell/SearchLoader'
import style from '../../style.module.scss'
import Asc from './Asc'
import DamageClu from './DamageClu'
import FilterBy from './FilterBy'
import FilterValue from './FilterValue'
import InclImage from './InclImage'
import Limit from './Limit'
import SortBy from './SortBy'
import StartFrom from './StartFrom'
import YearBy from './YearBy'

const ReqFliters = ({ onExecute, isAuthorized, isWind,isHistoricalYear, customOptions, filters, setFilters, isInclImage }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    if (!isAuthorized) return
    setIsLoading(true)
    await onExecute(filters)
    setIsLoading(false)
  }

  return (
    <div className={style.filtersForm}>
      <br />
      {
        isHistoricalYear &&
        <div>
          <h2>year:</h2>
          <YearBy
            setFilters={setFilters}
            filters={filters}
            classname={style.filterContainer}
          />
        </div>
      }
      <div>
        <h2>filter_by:</h2>
        <FilterBy
          filters={filters}
          setFilters={setFilters}
          classname={style.filterContainer}
          customOptions={customOptions}
        />
      </div>
      <div>
        <h2>filter_value:</h2>
        <FilterValue filters={filters} setFilters={setFilters} classname={style.filterContainer} />
      </div>
      {isWind && (
        <div>
          <h2>damage_clu:</h2>
          <DamageClu
            filters={filters}
            setFilters={setFilters}
            classname={style.filterContainer}
            customOptions={customOptions}
          />
        </div>
      )}
      <div>
        <h2>sort_by:</h2>
        <SortBy
          filters={filters}
          setFilters={setFilters}
          isWind={isWind}
          classname={style.filterContainer}
          customOptions={customOptions}
        />
      </div>
      <div>
        <h2>asc:</h2>
        <Asc filters={filters} setFilters={setFilters} classname={style.filterContainer} />
      </div>
      <div>
        <h2>start_from:</h2>
        <StartFrom filters={filters} setFilters={setFilters} classname={style.filterContainer} />
      </div>
      <div>
        <h2>limit:</h2>
        <Limit filters={filters} setFilters={setFilters} classname={style.filterContainer} />
      </div>
      {isInclImage && <div>
        <h2>incl_image:</h2>
        <InclImage filters={filters} setFilters={setFilters} classname={style.filterContainer} />
      </div>}
      {!isLoading ? (
        <button className={`${style.btnExecute} ${!isAuthorized ? style.disabled : ''}`} onClick={onSubmit}>
          Execute
        </button>
      ) : (
        <button className={`${style.loader} ${style.btnExecute}`}>
          <SearchLoader /> Loading
        </button>
      )}
    </div>
  )
}

export default ReqFliters
