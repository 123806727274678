// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_sectionWrapperGettingStarted__RTdV- {
  padding-block: 20px;
  border-bottom: 1px solid #cad3e2;
}
.style_sectionWrapperGettingStarted__RTdV-:last-child {
  padding-bottom: 0;
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/components/GettingStarted/SectionWrapperGettingStarted/style.module.scss"],"names":[],"mappings":"AAAA;EAKI,mBAAA;EACA,gCAAA;AAHJ;AAFI;EACI,iBAAA;EACA,mBAAA;AAIR","sourcesContent":[".sectionWrapperGettingStarted {\n    &:last-child{\n        padding-bottom: 0;\n        border-bottom: none;\n    }\n    padding-block: 20px;\n    border-bottom: 1px solid #cad3e2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionWrapperGettingStarted": `style_sectionWrapperGettingStarted__RTdV-`
};
export default ___CSS_LOADER_EXPORT___;
