import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import isSandbox from '../../../../../utils/isSandbox'
import style from '../style.module.scss'

function getLink(location) {
  const locationHash = location.hash.substring(1)
  const names = locationHash.split('/')
  let name = ''
  if (names?.length > 1) name = names[0]
  return name
}

const MenuItemDrop = ({ children, name, title, onClick, activeMenuItem }) => {
  const isSandboxEnv = isSandbox()
  const location = useLocation()
  const [isOpenDropDown, setIsOpenDropDown] = useState(false)

  const isOpen = useMemo(() => {
    const linkName = getLink(location)
    setIsOpenDropDown(true)
    return activeMenuItem === name || linkName === name
  }, [activeMenuItem])

  const handleClick = (event) => {
    event.preventDefault()
    const anchorTarget = document.getElementById(name)
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
    onClick(name)
    setIsOpenDropDown(!isOpenDropDown)
  }

  return (
    <div className={style.menuItemDrop}>
      <div
        className={`
        ${isSandboxEnv && style}
        ${(isOpen || isSandboxEnv) && style.activeDropActive} 
            ${
              isOpen && isOpenDropDown && name === 'api_calls' && !isSandboxEnv
                ? style.activeDropActiveBorderRadius
                : isSandboxEnv && style.sandBoxMenuItem
            }
            ${isOpen && isOpenDropDown && !isSandboxEnv && style.activeDropActiveBorder}`}
      >
        <a href={`#${name}`} onClick={handleClick}>
          <div>
            <h2>{title}</h2>
            {!isSandboxEnv && (
              <img src={`/media/${isOpen && isOpenDropDown ? 'minus' : 'plus'}.svg`} alt="getting started" />
            )}
          </div>
        </a>
      </div>
      {((isOpen && isOpenDropDown) || isSandboxEnv) && children}
    </div>
  )
}

export default MenuItemDrop
