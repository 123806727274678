import CodeSnippet from '../../CodeSnippet'
import tokenSample from '../../samples/tokenSample'
import GetTokenForm from '../GetTokenForm'
import ParameterList from '../../ParameterList'
import style from '../../style.module.scss'
import { useSelector } from 'react-redux'
import { useMemo, useRef, useState } from 'react'

import ResTypeCodeDisplay from '../../ResTypeCodeDisplay'
import resSampleGetToken from '../../samples/resSampleGetToken'
import CalcMarginBottom from '../../hooks/CalcMarginBottom'

const GetTokenReq = () => {
  const [activeCodePreview, setActiveCodePreview] = useState('200')
  const [resTypeCodeMarginBottom, setResTypeCodeMarginBottom] = useState(0)
  const [paramsMarginBottom, setParamsMarginBottom] = useState(0)
  const [codeConainerMarginBottom, setCodeConainerMarginBottom] = useState(0)
  const [filters, setFilters] = useState({
    apiKey: '',
    sessionLength: '',
  })

  const { token, screenSize } = useSelector((state) => state.user)
  const codeRef = useRef()
  const parametersReqRef = useRef()
  const resTypeCodeRef = useRef()
  const resTypeRef = useRef()

  const isScreenSizeParmeterMarginBottomDesktop = useMemo(() => {
    return screenSize.mode === 'Desktop' ? { marginBottom: `${paramsMarginBottom}px` } : {}
  }, [screenSize, paramsMarginBottom])

  const isScreenSizeDesktop = useMemo(() => {
    return screenSize.mode === 'Desktop' ? { marginBottom: `${resTypeCodeMarginBottom}px` } : {}
  }, [screenSize, resTypeCodeMarginBottom])

  const res = useMemo(() => {
    if (!activeCodePreview) return ''
    return activeCodePreview === '200' ? resSampleGetToken['200'] : resSampleGetToken['401']
  }, [activeCodePreview])

  CalcMarginBottom({
    setParamsMarginBottom,
    response: token,
    resTypeRef,
    resTypeCodeRef,
    setResTypeCodeMarginBottom,
    setCodeConainerMarginBottom,
    codeRef,
    parametersReqRef,
    type: 'token',
  })

  return (
    <div className={style.parameterContainer}>
      <div ref={parametersReqRef} style={isScreenSizeParmeterMarginBottomDesktop}>
        <h3>Parameters</h3>
        <ParameterList>
          <div className={style.sessionLengthContainer}>
            <div>
              <h4>session_length</h4>
              <h5>string</h5>
            </div>
            <div>
              <h6>The session length (in seconds)</h6>
            </div>
          </div>
          <div>
            <div>
              <h4>Api-Key</h4>
              <h5>
                <span className={style.required}>Required</span> string
              </h5>
            </div>
            <div>
              <h6>Enter the API secret key you received from PlanetWatchers here.</h6>
            </div>
          </div>
        </ParameterList>
      </div>
      <CodeSnippet
        codeSample={tokenSample}
        response={token}
        filters={{ ...filters }}
        title={'Request example'}
        marginBottom={codeConainerMarginBottom}
        codeRef={codeRef}
        resCodeDisplay={
          <ResTypeCodeDisplay
            setActiveCodePreview={setActiveCodePreview}
            activeCodePreview={activeCodePreview}
            res={res}
            reqType={'get_token'}
            resTypeCodeRef={resTypeCodeRef}
          />
        }
      >
        <GetTokenForm filters={filters} setFilters={setFilters} />
      </CodeSnippet>

      <div className={style.resTypeContainer} ref={resTypeRef} style={isScreenSizeDesktop}>
        <div>
          <h3>Responses</h3>
        </div>
        <ParameterList>
          <div>
            <div>
              <h4>200</h4>
            </div>
            <div>
              <h6 className={style.resType}>Success</h6>
            </div>
          </div>
          <div>
            <div>
              <h4>400</h4>
            </div>
            <div>
              <h6 className={style.resType}>Bad request</h6>
            </div>
          </div>
          <div>
            <div>
              <h4>401</h4>
            </div>
            <div>
              <h6 className={style.resType}>Unauthorized</h6>
            </div>
          </div>
        </ParameterList>
      </div>
    </div>
  )
}

export default GetTokenReq
