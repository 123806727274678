import { createSlice } from '@reduxjs/toolkit'

export const apiSlice = createSlice({
  name: 'api',
  initialState: {
    acreageReporting: '',
    acreageReportingHistory: '',
    acreageReportingAcres: '',
    acreageReportingCropType: '',
    acreageReportingPlantingDates: '',
    acreageReportingForecast: '',
    winds: '',
    harvest: '',
  },
  reducers: {
    setAcreageReporting: (state, action) => {
      state.acreageReporting = action.payload
    },
    setAcreageReportingHistory: (state, action) => {
      state.acreageReportingHistory = action.payload
    },
    setAcreageReportingForecast: (state, action) => {
      state.acreageReportingForecast = action.payload
    },
    setWinds: (state, action) => {
      state.winds = action.payload
    },
    setHarvest: (state, action) => {
      state.harvest = action.payload
    },
    setAcreageReportingAcres: (state, action) => {
      state.acreageReportingAcres = action.payload
    },
    setAcreageReportingCropType: (state, action) => {
      state.acreageReportingCropType = action.payload
    },
    setAcreageReportingPlantingDates: (state, action) => {
      state.acreageReportingPlantingDates = action.payload
    },
    setResetAfterLogout: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = ''
      })
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setAcreageReporting,
  setAcreageReportingHistory,
  setAcreageReportingAcres,
  setWinds,
  setAcreageReportingCropType,
  setResetAfterLogout,
  setAcreageReportingPlantingDates,
  setAcreageReportingForecast,
  setHarvest,
} = apiSlice.actions

export default apiSlice.reducer
