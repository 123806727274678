import {
  acreageReporting,
  acreageReportingAcres,
  acreageReportingCropType,
  acreageReportingForecast,
  acreageReportingHistory,
  acreageReportingPlantingDates,
  harvest,
  winds
} from '../../services/api.service'
import { authenticateSandbox } from '../../services/auth.sandbox.service'
import { authenticate } from '../../services/auth.service'
import { checkSessionValiditySandbox } from '../../services/user.sandbox.service'
import { checkSessionValidity } from '../../services/user.service'
import isSandbox from '../../utils/isSandbox'
import {
  setAcreageReporting,
  setAcreageReportingAcres,
  setAcreageReportingCropType,
  setAcreageReportingForecast,
  setAcreageReportingHistory,
  setAcreageReportingPlantingDates,
  setHarvest,
  setWinds,
} from '../slices/apiSlice'
import { setIsAuthorized, setToken } from '../slices/userSlice'

export function getToken(apiKey, sessionLength) {
  
  return async (dispatch) => {
    const isSandboxEnv = isSandbox()
    const response = isSandboxEnv
      ? await authenticateSandbox(apiKey, sessionLength)
      : await authenticate(apiKey, sessionLength)
    dispatch(setToken(response))
  }
}

export function checkIfTokenValid(session_token, session_token_sandbox) {
  return async (dispatch) => {
    const isSandboxEnv = isSandbox()
    const response = isSandboxEnv
      ? await checkSessionValiditySandbox(session_token, session_token_sandbox)
      : await checkSessionValidity(session_token)
    await dispatch(setIsAuthorized({ response, session_token }))
    return response
  }
}

export function getAcreageReportingForecast(filters, token) {
  return async (dispatch) => {
    const response = await acreageReportingForecast(filters, token)
    dispatch(setAcreageReportingForecast(response))
  }
}

export function getAcreageReportingPlantingDates(filters, token) {
  return async (dispatch) => {
    const response = await acreageReportingPlantingDates(filters, token)
    dispatch(setAcreageReportingPlantingDates(response))
  }
}

export function getAcreageReportingCropType(filters, token) {
  return async (dispatch) => {
    const response = await acreageReportingCropType(filters, token)
    dispatch(setAcreageReportingCropType(response))
  }
}

export function getAcreageReportingAcres(filters, token) {
  return async (dispatch) => {
    const response = await acreageReportingAcres(filters, token)
    dispatch(setAcreageReportingAcres(response))
  }
}

export function getAcreageReporting(filters, token) {
  return async (dispatch) => {
    const response = await acreageReporting(filters, token)
    dispatch(setAcreageReporting(response))
  }
}

export function getAcreageReportingHistory(filters, token) {
  return async (dispatch) => {
    const response = await acreageReportingHistory(filters, token)
    dispatch(setAcreageReportingHistory(response))
  }
}

export function getWind(filters, token) {
  return async (dispatch) => {
    const response = await winds(filters, token)
    dispatch(setWinds(response))
  }
}

export function getHarvest(filters, token) {
  return async (dispatch) => {
    const response = await harvest(filters, token)
    dispatch(setHarvest(response))
  }
}
