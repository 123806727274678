import axios from 'axios'

export async function checkSessionValidity(session_token) {
  try {
    await axios(`${process.env.REACT_APP_API_URL}/acreage_reporting`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${session_token}`,
        contentType: 'application/x-www-form-urlencoded',
      },
    })
    return true
  } catch (err) {
    if(err.response.status === 404) return true
    return false
  }
}